import React, { useCallback, useEffect, useRef } from 'react'
import { Button, Grid2, Typography } from '@mui/material'
import { Controller, UseFormReturn } from 'react-hook-form'
import MAutocomplete, { AutoCompleteOption } from '../material/MAutocomplete'
import MDateTimePicker from '../material/MDateTimePicker'
import MCheckbox from '../material/MCheckbox'
import MInput from '../material/MInput'
import SignatureCanvas from 'react-signature-canvas'
import { Order } from '../../types/order'
import { ralColorsPalette } from '../../utils/ralColorsPalette'
import { useParams } from 'react-router-dom'
import {
  finishesOptions,
  glossLevelOptions,
  paintTypeOptions,
} from '../../utils/selectorsOptions'
import useOrdersStore from '../../store/useOrdersStore'
import useDocumentsStore from '../../store/useDocumentsStore'

interface OrderDetailsFormProps {
  methods: UseFormReturn<Order>
}

const checkboxOptions = [
  { value: 'yes', label: 'Tak' },
  { value: 'no', label: 'Nie' },
]

export default function OrderDetailsForm(props: OrderDetailsFormProps) {
  const signatureRef = useRef<SignatureCanvas | null>(null)
  const orderId = useParams().orderId

  const { getCiNumber } = useOrdersStore()
  const { activeGrnNumber } = useDocumentsStore()

  const { setValue, control } = props.methods

  const clearSignatureCanvas = useCallback(() => {
    signatureRef?.current?.clear()
    setValue('signature', '', { shouldDirty: true, shouldValidate: true })
  }, [])

  const ralColorsOptions: AutoCompleteOption[] = Object.keys(
    ralColorsPalette,
  ).map((value) => ({ value, label: value }))

  useEffect(() => {
    if (!orderId && !activeGrnNumber) {
      getCiNumber().then((batchNumber) => {
        setValue('ciNumber', batchNumber)
      })
    } else if (!orderId && activeGrnNumber) {
      setValue('ciNumber', activeGrnNumber)
    }
  }, [])

  return (
    <Grid2 container direction="row" gap={4}>
      <Grid2 direction="column" container sx={{ flex: 1 }}>
        <Controller
          name="ralColor"
          control={control}
          render={({ field: { value } }) => (
            <MAutocomplete
              fullWidth
              value={value}
              onChange={(value: string) =>
                setValue('ralColor', value, {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              options={ralColorsOptions}
              label="Kolor RAL"
            />
          )}
        />
        <Controller
          name="glossLevel"
          control={control}
          render={({ field: { value } }) => (
            <MAutocomplete
              value={value}
              label="Stopień połysku"
              onChange={(value: string) =>
                setValue('glossLevel', value, {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              options={glossLevelOptions}
              fullWidth
            />
          )}
        />
        <Controller
          name="finishing"
          control={control}
          render={({ field: { value } }) => (
            <MAutocomplete
              value={value}
              label="Wykończenie"
              onChange={(value: string) =>
                setValue('finishing', value, {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              options={finishesOptions}
              fullWidth
            />
          )}
        />
        <Controller
          name="paintType"
          control={control}
          render={({ field: { value } }) => (
            <MAutocomplete
              value={value}
              label="Rodzaj farby"
              onChange={(value: string) =>
                setValue('paintType', value, {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              options={paintTypeOptions}
              fullWidth
            />
          )}
        />
        <Grid2 container direction="row" gap={2}>
          <Controller
            name="quantity"
            control={control}
            render={({ field: { value, onChange }, formState: { errors } }) => (
              <MInput
                style={{ width: 'calc(25% - 8px)' }}
                required
                variant="outlined"
                label="Ilość"
                type="number"
                value={value}
                onChange={onChange}
                helperText={errors.quantity?.message}
              />
            )}
          />
          <Controller
            name="product"
            control={control}
            render={({ field: { value }, formState: { errors } }) => (
              <MInput
                required
                style={{ width: 'calc(75% - 8px)' }}
                variant="outlined"
                label="Nazwa towaru"
                type="text"
                value={value}
                onChange={(event) =>
                  setValue('product', event.target.value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                helperText={errors.product?.message}
              />
            )}
          />
        </Grid2>
        <Grid2 container direction="row">
          <Grid2 style={{ flex: 1 }}>
            <Controller
              name="base"
              control={control}
              render={({ field: { value, onChange } }) => (
                <MCheckbox
                  onChange={onChange}
                  id="base"
                  label="Podkład"
                  defaultValue={value}
                  options={checkboxOptions}
                />
              )}
            />
          </Grid2>
          <Grid2 style={{ flex: 1 }}>
            <Controller
              name="sandblasting"
              control={control}
              render={({ field: { value, onChange } }) => (
                <MCheckbox
                  onChange={onChange}
                  id="sandblasting"
                  label="Piaskowanie"
                  defaultValue={value}
                  options={checkboxOptions}
                />
              )}
            />
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2 direction="column" container sx={{ flex: 1 }}>
        <Controller
          name="date"
          control={control}
          render={({ field: { value, onChange } }) => (
            <MDateTimePicker
              fullWidth
              value={value as number}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name="ciNumber"
          control={control}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <MInput
              required
              fullWidth
              variant="outlined"
              label="PZ"
              type="text"
              value={value}
              onChange={onChange}
              helperText={errors.ciNumber?.message}
            />
          )}
        />
        {!orderId && (
          <>
            <Typography variant="body2" color="textSecondary">
              Podpis zlecającego
            </Typography>
            <Grid2 container justifyContent="flex-end">
              <div
                style={{
                  border: '1px solid black',
                  width: '402px',
                  height: '152px',
                }}
              >
                <SignatureCanvas
                  ref={signatureRef}
                  penColor="black"
                  canvasProps={{ width: 400, height: 150 }}
                  onEnd={() => {
                    if (signatureRef.current) {
                      setValue(
                        'signature',
                        signatureRef.current
                          .getTrimmedCanvas()
                          .toDataURL('image/png'),
                        { shouldDirty: true, shouldValidate: true },
                      )
                    }
                  }}
                />
              </div>
            </Grid2>
            <Grid2 container direction="row" justifyContent="flex-end">
              <Button onClick={clearSignatureCanvas}>Wyczyść podpis</Button>
            </Grid2>
          </>
        )}
      </Grid2>
    </Grid2>
  )
}
