import React from 'react'
import { Grid2, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <Grid2 container alignItems="center" justifyContent="center">
      <Typography variant="body1" style={{ marginTop: '2rem' }}>
        Strona o takim adresie nie istnieje, wróć do{' '}
        <Link to="/">strony głównej</Link>
      </Typography>
    </Grid2>
  )
}

export default NotFound
