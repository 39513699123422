import React from 'react'
import { styled, TextField, TextFieldProps } from '@mui/material'

const SearchInput = styled(TextField)(() => ({
  width: '300px',
  marginBottom: '1rem',
  backgroundColor: 'transparent',
}))

interface MSearchFieldProps {
  fullWidth?: boolean
  helperText?: string
}

const MSearchField = (props: MSearchFieldProps & TextFieldProps) => {
  return (
    <SearchInput
      error={!!props.helperText}
      helperText={props.helperText}
      style={props.fullWidth ? { width: '100%' } : {}}
      {...props}
    />
  )
}

export default MSearchField
