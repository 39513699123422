import React from 'react'
import { styled, TextField, TextFieldProps } from '@mui/material'

const CustomInput = styled(TextField)(() => ({
  width: '300px',
  height: '90px',
  '& div': {
    backgroundColor: '#ffffff',
  },
}))

interface MInputProps {
  fullWidth?: boolean
  helperText?: string
}

const MInput = (props: MInputProps & TextFieldProps) => {
  return (
    <CustomInput
      error={
        !!props.helperText &&
        props.helperText !==
          'Liczba kopii jest automatycznie uzupełniana z danych formularza'
      }
      helperText={props.helperText}
      style={props.fullWidth ? { width: '100%' } : {}}
      {...props}
    />
  )
}

export default MInput
