import { OrderImage } from '../types/image'
import { create } from 'zustand'
import { callApi } from '../utils/callApi'
import { NotificationService } from '../service/NotificationService'

interface Store {
  orderSketches: OrderImage[]
  sketchesToUpload: File[]
  setSketchesToUpload: (sketchesToUpload: File[]) => void
  getSelectedOrderSketches: (orderId?: number) => Promise<OrderImage[]>
  uploadSketch: (orderId: number) => Promise<number>
  deleteSketch: (sketchId?: number) => Promise<number>
  removeSketchToUpload: (sketchToDelete: File) => void
  printSketch: (sketchId: number) => void
}

const useSketchesStore = create<Store>((set, get) => ({
  orderSketches: [],
  sketchesToUpload: [],

  setSketchesToUpload: (sketchesToUpload: File[]) => {
    set((state) => ({
      sketchesToUpload: [...state.sketchesToUpload, ...sketchesToUpload],
    }))
  },

  removeSketchToUpload: (sketchToDelete: File) => {
    set((state) => ({
      sketchesToUpload: state.sketchesToUpload.filter(
        (sketch) => sketch !== sketchToDelete,
      ),
    }))
  },

  getSelectedOrderSketches: async (orderId?: number) => {
    await callApi<{ data: OrderImage[] }>(
      `/api/sketches/${orderId}`,
      'GET',
    ).then((res) => {
      set(() => ({ orderSketches: res.data }))
    })

    return get().orderSketches
  },

  uploadSketch: async (orderId: number) => {
    if (get().sketchesToUpload.length > 0) {
      const formData = new FormData()

      get().sketchesToUpload.forEach((sketch) => {
        formData.append('sketches', sketch)
      })

      return await callApi<{ data: { message: string }; status: string }>(
        `/api/sketches/${orderId}`,
        'POST',
        formData,
      )
        .then((res) => {
          set(() => ({ sketchesToUpload: [] }))
          return res.status
        })
        .catch((error) => {
          NotificationService.showNotification(error.message, 'error')
          return error.status
        })
    }
  },

  deleteSketch: async (sketchId?: number) =>
    await callApi<{ data: { message: string }; status: string }>(
      `/api/sketches/${sketchId}`,
      'DELETE',
    )
      .then((res) => {
        set((state) => ({
          orderSketches: state.orderSketches.filter(
            (sketch) => sketch.id !== sketchId,
          ),
        }))
        return res.status
      })
      .catch((error) => {
        NotificationService.showNotification(error.message, 'error')
        return error.status
      }),

  printSketch: (sketchId) => callApi(`/api/sketches/print/${sketchId}`, 'POST'),
}))

export default useSketchesStore
