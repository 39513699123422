import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

interface GenerateDocumentDialogProps {
  open: boolean
  addAnotherOrderToDocument: () => void
  generateDocument: () => void
}

export default function GenerateDocumentDialog (props: GenerateDocumentDialogProps) {
  return (
    <Dialog open={props.open}>
      <DialogTitle>Generowanie PZ</DialogTitle>
      <DialogContent>
        <DialogContentText></DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.addAnotherOrderToDocument}>
          Dodaj następne zlecenie do PZ
        </Button>
        <Button onClick={props.generateDocument}>Wydrukuj PZ</Button>
      </DialogActions>
    </Dialog>
  )
}
