import React, { useEffect, useState } from 'react'
import {
  Grid2,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import useDocumentsStore from '../store/useDocumentsStore'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'
import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import PdfPreview from '../components/PdfPreview'
import { removeExtensionFromFileName } from '../utils/helpers'
import DeleteDialog from '../components/DeleteDialog'
import { filter, includes, isString, map, slice, some, toLower } from 'lodash'
import PrintIcon from '@mui/icons-material/Print'
import MSearchField from '../components/material/MSearchField'

const Container = styled('div')(() => ({
  padding: '1rem',
  height: '100%',
}))

const HeaderContainer = styled(Grid2)(() => ({
  marginBottom: '1rem',
}))

const Cell = styled(TableCell)(() => ({
  padding: '0.25rem 1rem',
}))

const Icon = styled(IconButton)(() => ({
  padding: '0.25rem 0.25rem',
}))

const DocumentsPage = () => {
  const rowsPerPage = 10
  const [search, setSearch] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [documentUrl, setDocumentUrl] = useState<string>('')
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const [idToDelete, setIdToDelete] = useState<number | null>(null)
  const [page, setPage] = useState<number>(0)

  const { documents, getDocuments, deleteDocument, printDocument } =
    useDocumentsStore()

  useEffect(() => {
    if (!documents.length) {
      setIsLoading(true)
      getDocuments().finally(() => {
        setIsLoading(false)
      })
    }
  }, [])

  const handleClose = () => {
    setOpen(false)
    setDocumentUrl('')
  }

  const handleOpenPreview = (url: string) => {
    setOpen(true)
    setDocumentUrl(url)
  }

  const deleteDialogControl = (id: number | null, isOpen: boolean) => {
    setIdToDelete(id)
    setOpenDeleteDialog(isOpen)
  }

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => {
    setPage(page)
  }

  const filteredDocuments = filter(documents, (obj) =>
    some(
      obj,
      (value) => isString(value) && includes(toLower(value), toLower(search)),
    ),
  )

  return (
    <Container>
      <HeaderContainer container direction="row" justifyContent="space-between">
        <Typography variant="h5">Documenty</Typography>
      </HeaderContainer>
      <MSearchField
        sx={{ '&: div': { backgroundColor: 'transparent' } }}
        variant="standard"
        placeholder="Wyszukaj..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <TableContainer
        style={{ maxHeight: 'calc(100dvh - 184px)', overflow: 'auto' }}
        component={Paper}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow style={{ height: '60px' }}>
              <TableCell width="50px">Id</TableCell>
              <TableCell style={{ width: 'calc(100dvw - 166px)' }} align="left">
                Dokument
              </TableCell>
              <TableCell width="130px" align="right">
                Akcje
              </TableCell>
            </TableRow>
          </TableHead>
          {!isLoading && (
            <TableBody>
              {map(
                slice(
                  filteredDocuments,
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage,
                ),
                (row) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    style={{ height: '50px' }}
                  >
                    <Cell component="th" scope="row">
                      {row.id}
                    </Cell>
                    <Cell align="left">
                      {removeExtensionFromFileName(row.documentName)}
                    </Cell>
                    <Cell align="center">
                      <Grid2 container direction="row" justifyContent="flex-end">
                        <Icon onClick={() => printDocument(row.id)}>
                          <PrintIcon style={{ color: '#000000' }} />
                        </Icon>
                        <Icon onClick={() => handleOpenPreview(row.path)}>
                          <VisibilityIcon style={{ color: '#000000' }} />
                        </Icon>
                        <Icon onClick={() => deleteDialogControl(row.id, true)}>
                          <DeleteIcon style={{ color: '#000000' }} />
                        </Icon>
                      </Grid2>
                    </Cell>
                  </TableRow>
                ),
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[rowsPerPage]}
        component="div"
        count={documents.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
      <PdfPreview url={documentUrl} open={open} handleClose={handleClose} />
      <DeleteDialog
        message="Czy na pewno chcesz usunąć ten dokument?"
        open={openDeleteDialog}
        closeDialog={() => deleteDialogControl(null, false)}
        deleteOrder={() => {
          if (idToDelete) deleteDocument(idToDelete)
          deleteDialogControl(null, false)
        }}
      />
    </Container>
  )
}

export default DocumentsPage
