import { create } from 'zustand'
import { callApi } from '../utils/callApi'
import { Order } from '../types/order'
import { NotificationService } from '../service/NotificationService'

interface Store {
  orders: Order[]
  getOrders: () => Promise<void>
  addOrder: (data: object) => Promise<{ statusCode: number; order: Order }>
  updateOrder: (id: number, data: object) => Promise<number>
  deleteOrder: (id: number) => void
  getCiNumber: () => Promise<string>
}

const useOrdersStore = create<Store>((set, get) => ({
  orders: [],

  getOrders: async () => {
    await callApi<{ data: Order[]; message: string }>(
      '/api/orders',
      'GET',
    ).then((res) => {
      set(() => ({ orders: res.data }))
    })
  },

  addOrder: async (data) =>
    await callApi<{ data: { order: Order; message: string }; status: number }>(
      '/api/orders',
      'POST',
      data,
    )
      .then((res) => {
        NotificationService.showNotification(res.data.message, 'success')
        set((state) => ({ orders: [...state.orders, res.data.order] }))
        return {
          statusCode: res.status,
          order: res.data.order,
        }
      })
      .catch((error) => {
        NotificationService.showNotification(error.message, 'error')
        return error.status
      }),

  updateOrder: async (id, data) =>
    await callApi<{ data: { order: Order; message: string }; status: number }>(
      `/api/orders/${id}`,
      'PUT',
      data,
    ).then((res) => {
      const index = get().orders.findIndex((order) => order.id === id)
      if (index !== -1) {
        NotificationService.showNotification(res.data.message, 'success')
        set((state) => {
          const orders = [...state.orders]
          orders[index] = res.data.order
          return { orders }
        })
      }
      return res.status
    }),

  deleteOrder: (id) =>
    callApi<{ data: { message: string } }>(`/api/orders/${id}`, 'DELETE').then(
      (res) => {
        NotificationService.showNotification(res.data.message, 'success')

        return set((state) => ({
          orders: state.orders.filter((order) => order.id !== id),
        }))
      },
    ),

  getCiNumber: async () => {
    const res = await callApi<{ data: string }>(
      `/api/orders/batchNumber`,
      'GET',
    )
    return res.data
  },
}))

export default useOrdersStore
