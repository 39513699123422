import React, { useEffect, useState } from 'react'
import { Grid2, IconButton, InputAdornment } from '@mui/material'
import { Controller, UseFormReturn } from 'react-hook-form'
import MInput from '../material/MInput'
import { Order } from '../../types/order'
import MAutocomplete, { AutoCompleteOption } from '../material/MAutocomplete'
import useContractorsStore from '../../store/useContractorsStore'
import MButton from '../material/MButton'
import SyncIcon from '@mui/icons-material/Sync'
import useAuthStore from '../../store/useAuthStore'
import { useNotification } from '../../context/NotificationContext'
import useDocumentsStore from '../../store/useDocumentsStore'
import { type Contractor } from '../../types/contractor'

interface AddContractorFormProps {
  methods: UseFormReturn<Order>
}

const AddContractorForm = ({ methods }: AddContractorFormProps) => {
  const { control, setValue, getValues } = methods
  const { contractors, getContractors, addContractor, updateContractor } =
    useContractorsStore()
  const { getDatFromGus } = useAuthStore()
  const { openNotification } = useNotification()
  const { activeTaxId } = useDocumentsStore()

  const taxId = getValues('taxId')
  const [selectedContractorId, setSelectedContractorId] = useState<number>(
    contractors.find((c) => c.taxId === taxId)?.id || -1,
  )
  const [selectedContractor, setSelectedContractor] =
    useState<Contractor | null>(null)

  useEffect(() => {
    if (selectedContractor) {
      setSelectedContractorId(selectedContractor.id)
      setValue('name', selectedContractor.name, {
        shouldDirty: true,
        shouldValidate: true,
      })
      setValue('taxId', selectedContractor.taxId, {
        shouldDirty: true,
        shouldValidate: true,
      })
      setValue('postalCode', selectedContractor.postalCode, {
        shouldDirty: true,
        shouldValidate: true,
      })
      setValue('city', selectedContractor.city, {
        shouldDirty: true,
        shouldValidate: true,
      })
      setValue('road', selectedContractor.road, {
        shouldDirty: true,
        shouldValidate: true,
      })
      setValue('phoneNumber', selectedContractor.phoneNumber, {
        shouldDirty: true,
        shouldValidate: true,
      })
    }
  }, [selectedContractor])

  useEffect(() => {
    getContractors()
  }, [])

  useEffect(() => {
    const contractor = contractors.find((c) => c.taxId === activeTaxId)
    if (contractor) {
      setSelectedContractor(contractor)
    }
  }, [])

  const handleSetContractor = (id: string) => {
    setSelectedContractorId(+id)
    const contractor = contractors.find((c) => c.id === +id)
    if (contractor) {
      setSelectedContractor(contractor)
    }
  }

  const contractorsOptions: AutoCompleteOption[] = contractors.map((c) => ({
    value: `${c.id}`,
    label: c.name,
  }))

  const doesSelectedContractorExist = () => {
    return contractors.some((c) => c.id === selectedContractorId)
  }

  const isValidContractor = () => {
    return (
      getValues('name') !== '' &&
      getValues('postalCode') !== '' &&
      getValues('city') !== '' &&
      getValues('road') !== '' &&
      getValues('phoneNumber') !== ''
    )
  }

  const getDetailsFromGus = async () => {
    try {
      const data = await getDatFromGus(getValues('taxId') || '')
      setValue('name', data.name)
      setValue('city', data.city)
      setValue('road', data.street)
      setValue('postalCode', data.postal_code)
    } catch (e) {
      openNotification((e as Error).message, 'error')
    }
  }

  return (
    <Grid2 container direction="row" gap={4}>
      <Grid2 direction="column" container sx={{ flex: 1 }}>
        <MAutocomplete
          value={
            selectedContractorId > 0 ? selectedContractorId.toString() : ''
          }
          label="Wybierz kontrahenta"
          onChange={handleSetContractor}
          options={contractorsOptions}
          fullWidth
        />
        <Controller
          name="taxId"
          control={control}
          render={({ field: { value, onChange } }) => (
            <MInput
              fullWidth
              variant="outlined"
              label="NIP"
              type="text"
              value={value}
              onChange={onChange}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disabled={getValues('taxId') === ''}
                        onClick={() => getDetailsFromGus()}
                        edge="end"
                      >
                        <SyncIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
          )}
        />
        <Controller
          name="name"
          control={control}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <MInput
              required
              fullWidth
              variant="outlined"
              label="Imię Nazwisko / Nazwa firmy"
              type="text"
              value={value}
              onChange={onChange}
              helperText={errors.name?.message}
            />
          )}
        />
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <MInput
              required
              fullWidth
              variant="outlined"
              label="Telefon kontaktowy"
              type="tel"
              value={value}
              onChange={onChange}
              helperText={errors.phoneNumber?.message}
            />
          )}
        />
      </Grid2>
      <Grid2 direction="column" container sx={{ flex: 1 }}>
        <Grid2
          container
          style={{ height: '56px', marginBottom: '34px' }}
          alignItems="center"
        >
          <MButton
            disabled={!isValidContractor()}
            style={{ width: 'fit-content' }}
            variant="contained"
            onClick={async () => {
              const contractor = {
                taxId: getValues('taxId'),
                name: getValues('name'),
                postalCode: getValues('postalCode'),
                city: getValues('city'),
                road: getValues('road'),
                phoneNumber: getValues('phoneNumber'),
              }
              if (doesSelectedContractorExist()) {
                await updateContractor(Number(selectedContractorId), contractor)
              } else {
                await addContractor(contractor)
              }
            }}
          >
            {doesSelectedContractorExist()
              ? 'Zaktualizuj kontrahenta'
              : 'Dodaj kontrahenta'}
          </MButton>
        </Grid2>
        <Controller
          name="postalCode"
          control={control}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <MInput
              required
              fullWidth
              variant="outlined"
              label="Kod pocztowy"
              type="text"
              value={value}
              onChange={onChange}
              helperText={errors.postalCode?.message}
            />
          )}
        />
        <Controller
          name="city"
          control={control}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <MInput
              required
              fullWidth
              variant="outlined"
              label="Miejscowość"
              type="text"
              value={value}
              onChange={onChange}
              helperText={errors.city?.message}
            />
          )}
        />
        <Controller
          name="road"
          control={control}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <MInput
              required
              fullWidth
              variant="outlined"
              label="Ulica"
              type="text"
              value={value}
              onChange={onChange}
              helperText={errors.road?.message}
            />
          )}
        />
      </Grid2>
    </Grid2>
  )
}

export default AddContractorForm
