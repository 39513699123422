import { Grid2 } from '@mui/material'
import React from 'react'
import { PhotoCamera } from '@mui/icons-material'
import MButton from './MButton'

interface MUploadButtonProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>
}

const MUploadButton = ({ onChange }: MUploadButtonProps) => {
  return (
    <Grid2 container gap="1rem">
      <Grid2 container justifyContent="flex-end" style={{ width: '100%' }}>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="image-upload"
          type="file"
          multiple
          onChange={onChange}
        />
        <label htmlFor="image-upload">
          <MButton
            style={{ width: 'fit-content' }}
            color="primary"
            variant="contained"
            aria-label="upload picture"
            component="span"
            startIcon={<PhotoCamera />}
          >
            Dodaj zdjęcia
          </MButton>
        </label>
      </Grid2>
    </Grid2>
  )
}

export default MUploadButton
