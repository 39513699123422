import React, { useEffect, useState } from 'react'
import {
  Grid2,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import MButton from '../components/material/MButton'
import useContractorsStore from '../store/useContractorsStore'
import { filter, includes, isString, map, slice, some, toLower } from 'lodash'
import VisibilityIcon from '@mui/icons-material/Visibility'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom'
import DeleteDialog from '../components/DeleteDialog'
import MSearchField from '../components/material/MSearchField'

const Container = styled('div')(() => ({
  padding: '1rem',
  height: '100%',
}))

const HeaderContainer = styled(Grid2)(() => ({
  marginBottom: '1rem',
}))

const Cell = styled(TableCell)(() => ({
  padding: '0.25rem 1rem',
}))

const Icon = styled(IconButton)(() => ({
  padding: '0.25rem 0.25rem',
}))

const ContractorsPage = () => {
  const rowsPerPage = 10
  const [search, setSearch] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const [idToDelete, setIdToDelete] = useState<number | null>(null)
  const navigate = useNavigate()

  const { contractors, getContractors, deleteContractor } =
    useContractorsStore()

  useEffect(() => {
    if (!contractors.length) {
      setIsLoading(true)
      getContractors().finally(() => {
        setIsLoading(false)
      })
    }
  }, [])

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => {
    setPage(page)
    const params = new URLSearchParams(location.search)
    params.set('page', String(page))
    navigate(`?${params.toString()}`)
  }

  const addNew = () => {
    navigate('/contractors/add-contractor')
  }

  const editOrder = (id: number) => {
    navigate(`/contractors/edit-contractor/${id}`)
  }

  const openContractorDetails = (id: number) => {
    navigate(`/contractors/${id}`)
  }

  const deleteDialogControl = (id: number | null, isOpen: boolean) => {
    setIdToDelete(id)
    setOpenDeleteDialog(isOpen)
  }

  const filteredContractors = filter(contractors, (obj) =>
    some(
      obj,
      (value) => isString(value) && includes(toLower(value), toLower(search)),
    ),
  )

  return (
    <Container>
      <HeaderContainer container direction="row" justifyContent="space-between">
        <Typography variant="h5">Kontrahenci</Typography>
        <MButton
          style={{ width: 'fit-content' }}
          startIcon={<AddIcon />}
          variant="contained"
          onClick={addNew}
        >
          Dodaj kontrahenta
        </MButton>
      </HeaderContainer>
      <MSearchField
        sx={{ '&: div': { backgroundColor: 'transparent' } }}
        variant="standard"
        placeholder="Wyszukaj..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <TableContainer
        style={{ maxHeight: 'calc(100dvh - 184px)', overflow: 'auto' }}
        component={Paper}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow style={{ height: '60px' }}>
              <TableCell width="50px">Id</TableCell>
              <TableCell width="30%" align="left">
                Nazwa
              </TableCell>
              <TableCell align="left">NIP</TableCell>
              <TableCell align="left">Telefon kontaktowy</TableCell>
              <TableCell width="128px" align="right">
                Akcje
              </TableCell>
            </TableRow>
          </TableHead>
          {!isLoading && (
            <TableBody>
              {map(
                slice(
                  filteredContractors,
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage,
                ),
                (row) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    style={{ height: '50px' }}
                  >
                    <Cell component="th" scope="row">
                      {row.id}
                    </Cell>
                    <Cell align="left">{row.name}</Cell>
                    <Cell align="left">{row.taxId}</Cell>
                    <Cell align="left">{row.phoneNumber}</Cell>
                    <Cell align="center">
                      <Grid2
                        container
                        direction="row"
                        justifyContent="flex-end"
                      >
                        <Icon onClick={() => openContractorDetails(row.id)}>
                          <VisibilityIcon style={{ color: '#000000' }} />
                        </Icon>
                        <Icon onClick={() => editOrder(row.id)}>
                          <EditIcon style={{ color: '#000000' }} />
                        </Icon>
                        <Icon onClick={() => deleteDialogControl(row.id, true)}>
                          <DeleteIcon style={{ color: '#000000' }} />
                        </Icon>
                      </Grid2>
                    </Cell>
                  </TableRow>
                ),
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        count={contractors.length}
        onPageChange={handleChangePage}
        page={page}
        rowsPerPage={rowsPerPage}
        component="div"
        rowsPerPageOptions={[rowsPerPage]}
      />
      <DeleteDialog
        message="Czy na pewno chcesz usunąć tego kontrahenta?"
        open={openDeleteDialog}
        closeDialog={() => deleteDialogControl(null, false)}
        deleteOrder={() => {
          if (idToDelete) deleteContractor(idToDelete)
          deleteDialogControl(null, false)
        }}
      />
    </Container>
  )
}

export default ContractorsPage
