import axios from 'axios'

export const callApi = async <T>(
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' = 'GET',
  body?: unknown,
): Promise<T> => {
  try {
    if (method === 'GET') {
      return await axios.get(url)
    } else if (method === 'POST') {
      return await axios.post(url, body)
    } else if (method === 'PUT') {
      return await axios.put(url, body)
    } else if (method === 'PATCH') {
      return await axios.patch(url, body)
    } else if (method === 'DELETE') {
      return await axios.delete(url, {
        data: body,
      })
    } else {
      throw new Error('Invalid HTTP method')
    }
  } catch (error) {
    // @ts-expect-error error dont have response
    throw error.response.data || error.message
  }
}
