import { useBlocker } from 'react-router-dom'
import { UseFormReturn } from 'react-hook-form'
import { Order } from '../types/order'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import React, { useEffect } from 'react'

interface RouteGuardDialogProps {
  methods: UseFormReturn<Order>
}

const RouteGuardDialog = ({ methods }: RouteGuardDialogProps) => {
  const { formState } = methods

  useEffect(() => {
    console.log(formState.isDirty)
  }, [formState.isDirty])

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (
      formState.isDirty && currentLocation.pathname !== nextLocation.pathname
    )
  })

  return (
    <Dialog open={blocker.state === 'blocked'}>
      <DialogTitle>Masz niezapisane zmiany</DialogTitle>
      {blocker.state === 'blocked' && (
        <>
          <DialogContent style={{ padding: '1.25rem', width: '600px' }}>
            <div>
              <Typography variant="subtitle1">
                Czy na pewno chcesz opuścić tą stronę? Wszystkie zmiany zostaną
                utracone!
              </Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => blocker.reset()}>Anuluj</Button>
            <Button onClick={() => blocker.proceed()}>Opuść</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default RouteGuardDialog
