import { create } from 'zustand'
import { Contractor } from '../types/contractor'
import { callApi } from '../utils/callApi'
import { NotificationService } from '../service/NotificationService'

interface Store {
  contractors: Contractor[]
  getContractors: () => Promise<void>
  addContractor: (data: object) => Promise<number>
  updateContractor: (id: number, data: object) => Promise<number>
  deleteContractor: (id: number) => void
}

const useContractorsStore = create<Store>((set, get) => ({
  contractors: [],

  getContractors: async () =>
    await callApi<{ data: Contractor[] }>('/api/contractors', 'GET').then(
      (res) => {
        return set(() => ({ contractors: res.data }))
      },
    ),

  addContractor: async (data) =>
    await callApi<{
      data: { contractor: Contractor; message: string }
      status: number
    }>('/api/contractors', 'POST', data)
      .then((res) => {
        NotificationService.showNotification(res.data.message, 'success')
        set((state) => ({
          contractors: [...state.contractors, res.data.contractor],
        }))
        return res.status
      })
      .catch((error) => {
        NotificationService.showNotification(error.message, 'error')
        return error.status
      }),

  updateContractor: async (id, data) =>
    await callApi<{
      data: { contractor: Contractor; message: string }
      status: number
    }>(`/api/contractors/${id}`, 'PUT', data).then((res) => {
      const index = get().contractors.findIndex(
        (contractor) => contractor.id === id,
      )
      if (index !== -1) {
        NotificationService.showNotification(res.data.message, 'success')
        set((state) => {
          const contractors = [...state.contractors]
          contractors[index] = res.data.contractor
          return { contractors }
        })
      }
      return res.status
    }),

  deleteContractor: (id: number) =>
    callApi<{ data: { message: string } }>(
      `/api/contractors/${id}`,
      'DELETE',
    ).then((res) => {
      NotificationService.showNotification(res.data.message, 'success')

      return set((state) => ({
        contractors: state.contractors.filter(
          (contractor) => contractor.id !== id,
        ),
      }))
    }),
}))

export default useContractorsStore
