import React from 'react'
import { Button, ButtonProps, styled } from '@mui/material'

const CustomButton = styled(Button)(() => ({
  maxWidth: '300px',
  backgroundColor: '#0d539e',
}))

const MButton = (props: ButtonProps) => {
  return <CustomButton {...props} />
}

export default MButton
